import React, { createContext } from 'react'
import ReactDOM from 'react-dom/client'
import { App } from './App'
import { BrowserRouter } from 'react-router-dom';
import { AppStore, AuthStore, EmployeeStore, LeadStore, ModalStore, WorkdayStore } from './store';
import './index.css';

export const AppContext = createContext(null)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<BrowserRouter>
		<AppContext.Provider value={{
			authStore: new AuthStore(),
			leadStore: new LeadStore(),
			employeeStore: new EmployeeStore(),
			workdayStore: new WorkdayStore(),
			modalStore: new ModalStore(),
			appStore: new AppStore(),
		}}>
			<App />
		</AppContext.Provider>
	</BrowserRouter>
)