import Chart from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Bar } from 'react-chartjs-2'
import React, { useContext, useEffect, useState } from 'react'
import { Export, Title } from 'src/components'
import { Main } from 'src/layout/Main/Main'
import { MainLeft } from 'src/layout/Main/MainLeft'
import { MainRight } from 'src/layout/Main/MainRight'
import { AppContext } from 'src'
import { observer } from 'mobx-react-lite'
import { AnalyticsFilter } from './AnalyticsFilter'
import style from './Analytics.module.css'
import { ExportAnalytics } from 'src/modals'

Chart.defaults.font.size = 14
Chart.defaults.font.family = 'Inter'


export const Analytics = observer(() => {
	const { leadStore, modalStore } = useContext(AppContext)
    const [isChart, setChart] = useState(false)
    const openChart = () => setChart(true)
    const openTable = () => setChart(false)

    const showChart = isChart
    const showTable = !isChart

	useEffect(() => {
		leadStore.getAnalytics()
        // leadStore.getSourceList()
	}, [leadStore.analytics_filter.dateFrom, leadStore.analytics_filter.dateTo, leadStore])

    // if (!leadStore.analytics_data || !leadStore.source_list.length) return <div></div>
    if (!leadStore.analytics_data || !leadStore._analytics_clubs.length) return <div></div>

    const dataKeys = Object.keys(leadStore?.analytics_data)
    const datesBetween = Object.keys(leadStore?.analytics_data[dataKeys[0]] || {})

    const firstRow = [
        `От: ${datesBetween[0]} До: ${datesBetween[datesBetween.length - 1]}`,
        ...datesBetween
    ]

    const clubLeadsTable = []
    const clubList = leadStore.analytics_filter?.clubs?.length
        ? leadStore.analytics_filter.clubs
        : leadStore._analytics_clubs

    const visitsByDateObj = {}

    for (let i = 0; i < clubList.length; i++) {
        const currentClub = clubList[i]
        const currentClubObj = leadStore.analytics_data[currentClub.id]
        const clubLeadsRow = [currentClub.name]

        for (let j = 0; j < datesBetween.length; j++) {
            const currentDate = datesBetween[j]
            clubLeadsRow.push(currentClubObj[currentDate])

            visitsByDateObj[currentDate]
                ? visitsByDateObj[currentDate] += currentClubObj[currentDate]
                : visitsByDateObj[currentDate] = currentClubObj[currentDate]
        }
    
        clubLeadsTable.push(clubLeadsRow)
    }

    const tableHeaderElements = firstRow.map((currentRowElement, index) => {
        return (
            <th
                key={index}
                className={style.table_item}
            >
                {currentRowElement}
            </th>
        )
    })

    const tableBodyElements = clubLeadsTable.map((currentRow, index) => {
        const rowElements = currentRow.map((currentRowElement, index) => {
            return (
                <td
                    key={index}
                    className={style.table_item}
                >
                    {currentRowElement}
                </td>
            )
        })
        return (
            <tr
                key={index}
                className={style.table_row}
            >
                {rowElements}
            </tr>
        )
    })

    const chartData = {
        labels: Object.keys(visitsByDateObj),
        datasets: [
            {
                backgroundColor: 'rgba(237, 105, 42, 1)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(237, 105, 42, 0.8)',
                hoverBorderColor: 'rgba(237, 105, 42, 1)',
                data: Object.values(visitsByDateObj),
                borderRadius: 8,
            }
        ]
    }

    const exportAnalyticsModal = () => modalStore.setModal(
		<ExportAnalytics onClose={() => { modalStore.setModal(null) } }/>)
		
    return (
        <Main>
            <MainLeft>
                <AnalyticsFilter/>
            </MainLeft>

            <MainRight>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					<Title>Обращения в клубы</Title>

                    <div className={style.menu}>
                        <button
                            type='button'
                            className={`${style.menu_link} ${showTable ? style.menu_link_active : ''}`}
                            onClick={openTable}
                        >
                            Таблица
                        </button>
                        <button
                            type='button'
                            className={`${style.menu_link} ${showChart ? style.menu_link_active : ''}`}
                            onClick={openChart}
                        >
                            Чарт
                        </button>
                    </div>
                    <Export onClick={exportAnalyticsModal} />
				</div>

				{showTable &&
                    <div className={style.table_container}>
                        <table className={style.table}>
                            <thead>
                                <tr className={`${style.table_row} ${style.table_row_h}`}>{tableHeaderElements}</tr>
                            </thead>
                            <tbody>{tableBodyElements}</tbody>
                        </table>
                    </div>}

                {showChart &&
                    <div style={{ height: '100%' }}>
                        <Bar
                            data={chartData}
                            plugins={[ChartDataLabels]}
                            options={{
                                maintainAspectRatio: false,
                                plugins: {
                                    datalabels: {
                                        anchor: 'end',
                                        align: 'start',
                                        offset: -20,
                                        padding: 2,

                                    },
                                    legend: {
                                        display: false
                                    },
                                },
                                scales: {
                                    y: {
                                        ticks: {
                                            padding: 20,
                                            precision: 0,
                                        },
                                    },
                                },
                            }}
                        />
                    </div>}
            </MainRight>
        </Main>
    )
})