export const NAME_MIN_LEN = process.env.NAME_MIN_LEN || 1
export const NAME_MAX_LEN = process.env.NAME_MAX_LEN || 100

export const LOGIN_MIN_LEN = process.env.LOGIN_MIN_LEN || 6
export const LOGIN_MAX_LEN = process.env.LOGIN_MAX_LEN || 30

export const PASSWORD_MIN_LEN = process.env.PASSWORD_MIN_LEN || 6
export const PASSWORD_MAX_LEN = process.env.PASSWORD_MAX_LEN || 30

export const ADMIN_SELECT_LIST = [
    { id: 1, name: 'Да' },
    { id: 2, name: 'Нет' },
]

export const SHOW_DATA_USERS = {
	// id: ['ID', '0.2'],
	name: ['Имя', '2.4'],
	login: ['Логин', '1.6'],
	super_admin: ['Супер Админ', '1.4', true],
	admin_of_day: ['Админ дня', '1.4', true],
	user_of_day: ['Пользователь дня', '1.4', true],
	observer: ['Наблюдатель', '1.4', true],
	status_name: ['Активен', '0.8', true],
}

export const SHOW_DATA_LEADS = {
	// id: ['ID', '0.5'],
	created_at: ['Дата', '2'],
	phone: ['Телефон', '1.8'],
	// employee_name: ['Сотрудник', '2'],
	source_name: ['Источник', '1.6'],
	status_name: ['Статус', '1', true],
}

export const SHOW_DATA_LEADS_ADMIN = {
	// id: ['ID', '0.3'],
	created_at: ['Дата', '2'],
	phone: ['Телефон', '1.8'],
	employee_name: ['Сотрудник', '2.5'],
	source_name: ['Источник', '1.5'],
	status_name: ['Статус', '1.2', true],
	// status: ['Статус', '1'],
}

export const SHOW_DATA_LEAD_EDIT = {
	created_at_short: 'Дата',
	phone: 'Телефон',
	club_name: 'Клуб',
	source_name: 'Источник',
}

export const SHOW_DATA_LEAD_REPEATS = {
	updated_at: ['Изменено', '1'],
	status_name: ['Статус', '0.7'],
	source_name: ['Источник', '1'],
	employee_name: ['Сотрудник', '1.8'],
}

export const COPY_DATA_LEADS = ['email', 'phone']
// export const COPY_DATA_LEADS = []

export const SORT_DATA_ITEM = 'created_at'

export const S_ADMIN_MENU_ITEMS = [
    // {to: '/', name: 'login_'},
    // {to: '/workday', name: 'workday_create_'},
    // {to: '/select_employees', name: 'select_empls_'},
    // {to: '/check_selected_employees', name: 'check_selected_empls_'},
    { to: '/leads', name: 'Заявки' },
    { to: '/employees', name: 'Сотрудники' },
    { to: '/analytics', name: 'Аналитика' },
]

export const OBSERVER_MENU_ITEMS = [
    { to: '/leads', name: 'Заявки' },
    { to: '/analytics', name: 'Аналитика' },
]

export const itemColor = {
	table_item_green: ['Да', 'Создан'],
	table_item_red: ['Нет', 'Отказ', 'Отложен'],
	table_item_dark: ['Дубль', 'ЧК', 'Фрод'],
	table_item_yellow: ['Недозвон'],
	table_item_blue: ['Б24'],
}

export const PAGINATION_OPTIONS = [
	{ id: 1, name: 'Только снизу' },
	{ id: 2, name: 'Только сверху' },
	{ id: 3, name: 'Снизу и сверху' }
]
export const YES_NO_OPTIONS = [
	{ id: 1, name: 'Да', value: true },
	{ id: 2, name: 'Нет', value: false }
]

export const NAMED_DATA_PROPERTIES = {
	id: 'Id заявки',
	created_at: 'Дата создания',
	phone: 'Телефон',
	employee_name: 'Сотрудник',
	source_name: 'Источник',
	status_name: 'Статус',
	club_name: 'Клуб',
	personal_data: 'Обработка персональных данных',
	rim: 'Согласие на рассылку',
	link: 'Ссылка Б24',
	allocated_at: 'Дата распределения',
	updated_at: 'Дата обновления',
	note: 'Комментарии',
	'form_name': 'Название формы',
	'club': 'Клуб Event',
	'source': 'Откуда узнали о нас',
	'managerFullName': 'Имя сервис менеджера',
	'rim4Hands': 'Согласие на рассылку 4hands',
}