import { authApi } from './config'
import { makeQueryParamsStr } from 'src/assets/utils'


export const getLeads = async (filter) => {
    try {
        const response = await authApi.get('/lead?' + makeQueryParamsStr(filter))
        return response
    } catch (err) {
        return err.response
    }
}

export const getEmployeeList = async () => {
    try {
        const response = await authApi.get('/employee?all=true')
        return response
    } catch (err) {
        return err.response
    }
}

export const getSourceList = async () => {
    try {
        const response = await authApi.get('/lead/source/?all=true')
        return response
    } catch (err) {
        return err.response
    }
}

export const setStatus = async (data) => {
    try {
        const response = await authApi.put('/lead/' + data.id, { ...data })
        return response
    } catch (err) {
        return err.response
    }
}



export const getRepeats = async (id) => {
    try {
        const response = await authApi.get('/lead/repeat/' + id)
        return response
    } catch (err) {
        return err.response
    }
}

export const getAnalytics = async (filter) => {
    try {
        const response = await authApi.get('/lead/analytics?' + makeQueryParamsStr(filter))
        return response
    } catch (err) {
        return err.response
    }
}

// export const getStatusList = async () => {
//     try {
//         const response = await authApi.get('/status/list')
//         return response
//     } catch (err) {
//         return err.response.data
//     }
// }