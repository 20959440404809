import { observer } from 'mobx-react-lite'
import { useContext, useEffect, useState } from 'react'
import { AppContext } from 'src'
import { Block, Button, CloseBtn, Fieldset, Form, Input, Label, Select, Tag, TagList, Title } from 'src/components'

const minDate = '2023-01-01'
const maxDate = new Date().toLocaleDateString().split('.').reverse().join('-')

export const AnalyticsFilter = observer(() => {
    const { leadStore } = useContext(AppContext)
    const [filter, setFilter] = useState(leadStore.analytics_filter)

    useEffect(() => {
        setFilter(leadStore.analytics_filter)
    }, [leadStore.analytics_filter])

    const canApplyFilter = 
        JSON.stringify({ ...filter }) !== 
        JSON.stringify({ ...leadStore.analytics_filter })

    const applyFilter = () => {
        leadStore.set_analytics_filter({
            ...filter,
        })
    }

    const resetFilter = () => {
        leadStore.reset_analytics_filter()
        setFilter(leadStore.analytics_filter)
    }

    const handleDateSelect = (propertyName) => {
        return (date) => setFilter(prev => ({ ...prev, [propertyName]: date }))
    }

    const isSourceFilter = filter.clubs?.length > 0
    const isDateFilter = filter.dateFrom || filter.dateTo

    return (
        <Block>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Title medium>Фильтр</Title>
            </div>

            <Form>
                <Fieldset>
                    <div className='flex-sb-c'>
                        <Label small>Клуб</Label>
                        {isSourceFilter &&
                            <CloseBtn
                                small
                                onClick={() => setFilter(prev => ({ ...prev, clubs: [] }) )}
                            />}
                    </div>
                    <Select
                        value={filter.clubs}
                        onChange={(src) => setFilter(prev => ({ ...prev, clubs: src }))}
                        options={leadStore._analytics_clubs}
                        placeholder='Выберите источник'
                        small multiple
                    />
                    {isSourceFilter &&
                        <TagList>
                            {filter.clubs.map((src, idx) => {
                                return <Tag
                                    onDelete={() => setFilter(prev => ({ ...prev, clubs: prev.clubs.filter((s) => s.id !== src.id) }))}
                                    key={idx + Date.now()}
                                >
                                    {src.name}
                                </Tag>
                            }
                            )}
                        </TagList>}
                </Fieldset>
                <Fieldset>
                    <div className='flex-sb-c'>
                        <Label small>Дата</Label>
                        {isDateFilter &&
                            <CloseBtn
                                small
                                onClick={() => setFilter(prev => ({ ...prev, dateFrom: '', dateTo: ''}) )}
                            />}
                    </div>
                    <div style={{ display: 'flex', gap: 10 }}>
                        <Input
                            style={{ width: 132 }}
                            type='date'
                            value={filter.dateFrom}
                            onChange={handleDateSelect('dateFrom')}
                            min={minDate} max={leadStore.analytics_filter.dateTo || maxDate}
                            small placeholder='Начало'
                        />
                        <Input
                            style={{ width: 132 }}
                            type='date'
                            value={filter.dateTo}
                            onChange={handleDateSelect('dateTo')}
                            min={leadStore.analytics_filter.dateFrom || minDate} max={maxDate}
                            small placeholder='Конец'
                        />
                    </div>
                </Fieldset>
            </Form>

            <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                <Button
                    onClick={applyFilter}
                    small
                    disabled={!canApplyFilter}
                >
                    Применить
                </Button>
                <Button
                    onClick={resetFilter}
                    small light
                >
                    Сбросить
                </Button>
            </div>
        </Block>
    )
})