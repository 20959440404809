import { makeAutoObservable, toJS } from 'mobx'
import { leadApi } from 'src/http'

const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']
const paramseToParse = ['form_name', 'tariff', 'club', 'source', 'managerFullName', 'rim4Hands']

const INIT_FILTER = {
    all: false,
    limit: 50,
    page: 1,
    employee: [],
    status: [{ id: 0, name: 'Создан' }],
    source: [],
    dateFrom: '',
    dateTo: '',
    phone: '',
    sort: true,
}

const INIT_EXPORT_DATA_PROPS = ["id","phone","name","email","club_name","personal_data","rim","link","employee_name","source_name","created_at","updated_at","note","status_name", ...utmParams]

const SAVED_FILTER = JSON.parse(localStorage.getItem('crm_filter'))
const SAVED_EXPORT_DATA_PROPS = JSON.parse(localStorage.getItem('crm_export_data_properties'))

const today = new Date()
const sevenDaysAgo = new Date()
sevenDaysAgo.setDate(today.getDate() - 6)

const ANALYTICS_INIT_FILTER = {
    clubs: [],
    dateFrom: sevenDaysAgo.toLocaleDateString().split('.').reverse().join('-'),
    dateTo: today.toLocaleDateString().split('.').reverse().join('-'),
}

const ANALYTICS_SAVED_FILTER = JSON.parse(localStorage.getItem('crm_analytics_filter'))

class LeadStore {

    // analytics data
    _analytics_data = null
    _analytics_clubs = null

    // analytics filtering params
    analytics_filter = ANALYTICS_SAVED_FILTER || ANALYTICS_INIT_FILTER

    new_lead_counter = 0

    // leads data
    leads = []
    leadsAll = []
    pages = null
    records = null
    limits = [10,25,50]

    //
    dataToExport = SAVED_EXPORT_DATA_PROPS || INIT_EXPORT_DATA_PROPS

    // single lead
    lead = null

    repeats = []

    // filter data
    employee_list = []
    status_list = [
        { id: 0, name: 'Создан' },
        { id: 1, name: 'Недозвон' },
        { id: 7, name: 'Отложен' },
        { id: 2, name: 'ЧК' },
        { id: 3, name: 'Дубль' },
        { id: 4, name: 'Фрод' },
        { id: 5, name: 'Б24' },
        { id: 6, name: 'Отказ' },
    ]
    source_list = []

    // lead filtering params
    filter = SAVED_FILTER || INIT_FILTER

    // common
    loading = false
    error = ''


    constructor() {
        makeAutoObservable(this, {}, { deep: true })
    }

    close_message() {
        this.new_lead_counter = 0
    }

    show_new_leads() {
        this.filter = { ...this.filter, page: 1, sort: true, status: [{ id: 0, name: 'Создан' }] }
        this.saveFilter()
    }


    ////////////////////////////////////////////////////////////////// 
    // GET ANALYTICS (available for admin) => (gets params list)
    //////////////////////////////////////////////////////////////////

    *getAnalytics() {
        this.loading = true
        const response = yield leadApi.getAnalytics({ dateFrom: this.analytics_filter.dateFrom, dateTo: this.analytics_filter.dateTo })

        if (!response) {
            this.handleError('Ошибка соединения с сервером')
            return
        }

        if (response.status !== 200) {
            this.handleError(response.data.message)
            return
        }

        this._analytics_data = response.data.leads
        this._analytics_clubs = response.data.clubs
        this.handleSuccess()
    }

    get analytics_data() {
        if (!this._analytics_data) {
            return null
        }

        if (this.analytics_filter.clubs?.length) {
            const newObject = {}
            Object.keys(this._analytics_data)
                .filter(currentKey => this.analytics_filter.clubs?.some(currentClub => +currentClub.id === +currentKey))
                .sort()
                .forEach(currentKey => newObject[currentKey] = toJS(this._analytics_data[currentKey]))
            return newObject
        }
        return this._analytics_data
    }


    set_analytics_filter(filter) {
        this.analytics_filter = filter
        this.saveAnalyticsFilter()
    }

    reset_analytics_filter() {
        this.analytics_filter = {
            ...ANALYTICS_INIT_FILTER,
        }
        this.saveAnalyticsFilter()
    }

    saveAnalyticsFilter() {
        localStorage.setItem('crm_analytics_filter', JSON.stringify({ ...this.analytics_filter }))
    }

    ////////////////////////////////////////////////////////////////// 
    // GET LEADS (available for every user) => (gets params list)
    //////////////////////////////////////////////////////////////////

    *getLeads() {
        this.loading = true
        const response = yield leadApi.getLeads(this.filter)

        if (!response) {
            this.handleError('Ошибка соединения с сервером')
            return
        }

        if (response.status !== 200) {
            this.handleError(response.data.message)
            return
        }

        this.new_lead_counter = 0
        this.leads = response.data.leads.map(l => ({ 
            ...l, 
            created_at_short: new Date(l.created_at).toLocaleDateString(),
            created_at: new Date(l.created_at).toLocaleString(),
            status_name: this.status_list.filter(s => s.id === +l.status)[0]?.name,
            note: JSON.stringify((() => {
                const note = JSON.parse(l.note)
                utmParams.forEach(param => {
                    if (note[param]?.length) {
                        delete note[param]
                    }
                })
                return note
            })())
        }))
        this.pages = response.data.pages
        this.records = response.data.records
        this.handleSuccess()
    }


    ////////////////////////////////////////////////////////////////// 
    // GET REPEATS (available for every user) => (gets params list)
    //////////////////////////////////////////////////////////////////

    *getRepeats() {
        this.loading = true
        const response = yield leadApi.getRepeats(this.lead.id)

        if (!response) {
            this.handleError('Ошибка соединения с сервером')
            return
        }

        if (response.status !== 200) {
            this.handleError(response.data.message)
            return
        }

        this.repeats = response.data.repeats.map(l => ({ ...l, 
            created_at: new Date(l.created_at).toLocaleDateString(),
            updated_at: new Date(l.updated_at).toLocaleDateString(),
            status_name: this.status_list.filter(s => s.id === +l.status)[0]?.name }))
        this.handleSuccess()
    }

    ////////////////////////////////////////////////////////////////// 
    // GET LEADS ALL - FOR DATA EXPORT (available for every user) => (gets params list)
    //////////////////////////////////////////////////////////////////

    *getLeadsAll() {
        this.loading = true
        const response = yield leadApi.getLeads({ ...this.filter, all: true })

        if (!response) {
            this.handleError('Ошибка соединения с сервером')
            return
        }

        if (response.status !== 200) {
            this.handleError(response.data.message)
            return
        }

        this.leadsAll = response.data.leads.map(l => {
            const note = JSON.parse(l.note)
            const parsedParams = {}
            const allParams = [...paramseToParse, ...utmParams]
            allParams.forEach(param => {
                parsedParams[param] = note[param]
                delete note[param]
            })
            
            return {
                ...l,
                status_name: this.status_list.filter(s => s.id === +l.status)[0]?.name,
                ...parsedParams,
                note: JSON.stringify(note),
                phone: +l.phone
            }
        })
        
        this.handleSuccess()
    }


    ////////////////////////////////////////////////////////////////// 
    // GET EMPLOYEE LIST for filter (available only for super admins)
    //////////////////////////////////////////////////////////////////

    *getEmployeeList() {
        this.loading = true
        const response = yield leadApi.getEmployeeList()

        if (!response) {
            this.handleError('Ошибка соединения с сервером')
            return
        }

        if (response.status !== 200) {
            this.handleError(response.data.message)
            return
        }

        this.employee_list = response.data.employees.map((emp) => ({ id: emp.id, name: emp.name }))
        this.handleSuccess()
    }


    ////////////////////////////////////////////////////////////////// 
    // GET SOURCE LIST for filter (available for every user)
    //////////////////////////////////////////////////////////////////

    *getSourceList() {
        this.loading = true
        const response = yield leadApi.getSourceList()

        if (!response) {
            this.handleError('Ошибка соединения с сервером')
            return
        }

        if (response.status !== 200) {
            this.handleError(response.data.message)
            return
        }

        this.source_list = response.data.sources
        this.handleSuccess()
    }


    ////////////////////////////////////////////////////////////////// 
    // SET ONE LEAD
    //////////////////////////////////////////////////////////////////


    selectLead(id) {
        this.lead = this.leads.find(l => l.id === id)
    }


    ////////////////////////////////////////////////////////////////// 
    // UPDATE LEAD (FOR SSE)
    //////////////////////////////////////////////////////////////////

    updateLead(data) {
        this.leads = this.leads.map(l => {
            const updatedLead = data.find(upd_lead => upd_lead.id === l.id)
            if (updatedLead) {
                return { ...l, ...updatedLead, status_name: this.status_list.filter(s => s.id === +updatedLead.status)[0]?.name }
            }
            return { ...l }
        })

        const updatedLead = data.find(upd_lead => upd_lead.id === this.lead?.id)
        if (updatedLead) {
            this.lead = { ...this.lead, ...updatedLead, status_name: this.status_list.filter(s => s.id === +updatedLead.status)[0]?.name }
        }
    }


    ////////////////////////////////////////////////////////////////// 
    // NEW LEAD COUNTER INCREASE
    //////////////////////////////////////////////////////////////////

    increaseCounter() {
        this.new_lead_counter += 1
    }

    ////////////////////////////////////////////////////////////////// 
    // GET STATUS LIST for filter (available for every user)
    //////////////////////////////////////////////////////////////////

    // *getStatusList() {
    //     this.loading = true
    //     const response = yield // leadApi.getStatusList()

    //     if (response.status !== 200) {
    //         this.handleError(response.statusText)
    //         return
    //     }

    //     this.status_list = response.data
    //     this.loading = false
    //     this.error = ''
    // }
    


    ////////////////////////////////////////////////////////////////// 
    // SET LEAD STATUS (available to allocated employee or super admin)
    //////////////////////////////////////////////////////////////////

    *set_lead_status(data) {
        this.loading = true
        const response = yield leadApi.setStatus(data)

        if (!response) {
            this.handleError('Ошибка соединения с сервером')
            return
        }

        if (response.status !== 200) {
            this.handleError(response.data.message)
            return
        }

        // this.leads = this.leads.map((lead) => {
        //     if (lead.id === data.id) {
        //         return { ...lead, ...data }
        //     }
        //     return { ...lead }
        // })
        this.handleSuccess()
    }


    ////////////////////////////////////////////////////////////////// 
    // SET / DELETE FILTER ()
    //////////////////////////////////////////////////////////////////

    set_filter(filter) {
        this.filter = filter
        this.saveFilter()
    }

    reset_filter() {
        this.filter = {
            ...INIT_FILTER,
            limit: this.filter.limit
        }
        this.saveFilter()
    }


    ////////////////////////////////////////////////////////////////// 
    // SET PAGE
    //////////////////////////////////////////////////////////////////

    set_page(page) {
        this.filter = { ...this.filter, page: (page > this.pages) ? 1 : page } 
    }


    ////////////////////////////////////////////////////////////////// 
    // SET LIMIT
    //////////////////////////////////////////////////////////////////

    set_limit(limit) {
        this.filter = { ...this.filter, limit: (this.limits.indexOf(limit) < 0) ? this.limits[0] : limit, page: 1 }
        this.saveFilter()
    }

    toggle_sort() {
        this.filter = { ...this.filter, sort: !this.filter.sort, page: 1 }
        this.saveFilter()
    }


    saveFilter() {
        localStorage.setItem('crm_filter', JSON.stringify({ ...this.filter, page: 1 }))
    }

    setDataToExport(dataToExport) {
        this.dataToExport = dataToExport
        this.saveDataToExport()
    }

    saveDataToExport() {
        localStorage.setItem('crm_export_data_properties', JSON.stringify(this.dataToExport))
    }

    ////////////////////////////////////////////////////////////////// 
    // COMMON ERROR HANDLER (sets error message and loading = false)
    //////////////////////////////////////////////////////////////////

    handleError(message) {
        this.error = message
        this.loading = false
    }

    handleSuccess() {
        this.error = ''
        this.loading = false
    }
}

export default LeadStore